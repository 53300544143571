// 首页

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Carousel, Form, Input, message, Modal, notification, Statistic } from 'antd';
import { useEffect, useState, useRef } from 'react';
import cookie from 'react-cookies';
import fun from '../../common/common_fun';
import url from '../../common/url_config';
import FooterBottom from '../../components/menu/footer';
import HeaderTop from '../../components/menu/header';
import Banner from '../../components/banner/index';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import CountUp from 'react-countup';

function App() {
  let [data, set_data] = useState({
    '了解宣怀数据1': {},
    '了解宣怀数据2': {},
    '了解宣怀数据3': {},
    '了解宣怀数据4': {},
  })//底部红底数据

  let [data_delay, set_data_delay] = useState({
    '了解宣怀数据1': {},
    '了解宣怀数据2': {},
    '了解宣怀数据3': {},
    '了解宣怀数据4': {},
  })//底部红底数据

  // 获取底部红底数据
  const get_data = (is_delay = false) => {
    fun['post_data'](url + 'get_index_data', {}).then(res => {
      if (res.status) {
        if (is_delay) {
          set_data_delay(res.result)
        } else {
          set_data(res.result)
        }
      } else {
        message.error('failed:' + JSON.stringify(res.result))
      }
    })
  }

  // 监听页面滑动元素
  const elementRef = useRef(null);
  useEffect(() => {
    get_data()

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 当元素进入视口时，执行你想要的操作
          console.log('Element is in view!');
          get_data(true)
        }
      });
    });
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  // wow初始化
  useEffect(() => {
    new WOW.WOW({
      boxClass: 'wow', // 动画的类名
      animateClass: 'animated', // 动画应用的类名
      offset: 0, // 触发动画的位置偏移量
      mobile: true, // 是否在移动端上启用
      live: true // 如果为true，则在页面加载后动态添加的新元素也会触发动画
    }).init();
  }, []);

  return (
    <div>
      <HeaderTop />
      <Banner page='首页' />
      <div style={{ paddingBottom: '15%', paddingTop: '2%' }} className='index_bg'>
        <div className='btn_box'>
          <img style={{ width: '50%' }} src={url + 'public/web/首页/index_img_1.png'} />
          <div style={{ width: '35%', marginRight: '10%' }}>
            <div className="index_title_1 wow slideInRight" data-wow-duration="1s">发 现</div>
            <div className='index_title_2'>{data['发现'] ? data['发现']['值1'] : ''}</div>
            <div className='index_title_3'>{data['发现'] ? data['发现']['值2'] : ''}</div>
            <a href='/activity' className='index_title_4'>MORE +</a>
          </div>
        </div>

        <div style={{ marginTop: '-10%' }} className='btn_box'>
          <div style={{ width: '35%', marginLeft: '10%', marginTop: '10%' }}>
            <div className="index_title_1 wow slideInLeft" data-wow-duration="1s">赋 能</div>
            <div className='index_title_2'>{data['赋能'] ? data['赋能']['值1'] : ''}</div>
            <div className='index_title_3'>{data['赋能'] ? data['赋能']['值2'] : ''}</div>
            <a href='/course' className='index_title_4'>MORE +</a>
          </div>
          <img style={{ width: '50%' }} src={url + 'public/web/首页/index_img_2.png'} />
        </div>

        <div style={{ position: 'relative' }} className='btn_box'>
          <div style={{ position: 'absolute', left: '32%', bottom: '15%' }}>LEARNING BY DOING</div>
          <img style={{ width: '45%', marginLeft: '10%', marginTop: '10%' }} src={url + 'public/web/首页/index_img_3.png'} />
          <div style={{ width: '35%', marginRight: '5%' }}>
            <div className="index_title_1 wow slideInRight" data-wow-duration="1s">加 速</div>
            <div className='index_title_2'>{data['加速'] ? data['加速']['值1'] : ''}</div>
            <div className='index_title_3'>{data['加速'] ? data['加速']['值2'] : ''}</div>
            <a href='/entrepreneurship_practice/x_lab' className='index_title_4'>MORE +</a>
          </div>
        </div>

        <div style={{ marginTop: '5%' }} className='btn_box'>
          <div style={{ width: '35%', marginLeft: '10%', }}>
            <div className="index_title_1 wow slideInLeft" data-wow-duration="1s">
              <div>创</div>
              <div style={{ marginTop: 60 }}>&nbsp;&nbsp;业</div>
            </div>
            <div className='index_title_2'>{data['创业'] ? data['创业']['值1'] : ''}</div>
            <div className='index_title_3'>{data['创业'] ? data['创业']['值2'] : ''}</div>
            <a href='/entrepreneurship_incubation/teacher_studio' className='index_title_4'>MORE +</a>
          </div>
          <img style={{ width: '40%', marginRight: '10%', marginTop: '10%' }} src={url + 'public/web/首页/index_img_4.png'} />
        </div>
      </div>


      <div id='index_bottom_red_top' ref={elementRef}>
        <div className='index_bottom_red'>
          <div className='main_width'>
            <div style={{ textAlign: 'center', fontSize: 30 }}>了解宣怀</div>
            <div style={{ marginTop: 30 }} className='btn_box'>
              <div className='index_bottom_red_each'>
                <div className='index_bottom_red_title_each'>{data_delay['了解宣怀数据1']['值2']}</div>
                <div>{data_delay['了解宣怀数据1']['值1']}</div>
              </div>

              <div className='index_bottom_red_each'>
                <div className='index_bottom_red_title_each'>{<CountUp end={data_delay['了解宣怀数据2']['值2']} separator="," />}</div>
                <div>{data_delay['了解宣怀数据2']['值1']}</div>
              </div>
              <div className='index_bottom_red_each'>
                <div className='index_bottom_red_title_each'>{<CountUp end={data_delay['了解宣怀数据3']['值2']} separator="," />}+</div>
                <div>{data_delay['了解宣怀数据3']['值1']}</div>
              </div>
              <div className='index_bottom_red_each'>
                <div className='index_bottom_red_title_each'>{<CountUp end={data_delay['了解宣怀数据4']['值2']} separator="," />}+</div>
                <div>{data_delay['了解宣怀数据4']['值1']}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </div >
  )
}
export default App