// 配置路由文件

import { UserOutlined, AppstoreOutlined, FileDoneOutlined, MoneyCollectOutlined, FundViewOutlined, PlusSquareOutlined, AccountBookOutlined, SettingOutlined, SmileOutlined, GiftOutlined, TagsOutlined, SolutionOutlined, MonitorOutlined, CarryOutOutlined, TeamOutlined, GlobalOutlined, BarChartOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: '登录',
    route: '/login',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '关于我们',
    route: '/double_create',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '新闻动态',
    route: '/news',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '双创活动',
    route: '/activity',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '双创课程',
    route: '/course',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '学生课程详情（已废弃）',
    route: '/course_detail',
    icon: <UserOutlined />,
    hidden: true,
  },

  {
    title: '证书项目',
    route: '/edu_project',
    icon: <UserOutlined />,
    hidden: true,
    para: 'type',
  },
  {
    title: '证书项目-核心课程',
    route: '/edu_project_core_course',
    icon: <UserOutlined />,
    hidden: true,
    para: 'type',
  },
  {
    title: '证书项目-项目特色',
    route: '/edu_project_characteristic',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '证书项目-招生计划',
    route: '/edu_project_enrollment_plan',
    icon: <UserOutlined />,
    hidden: true,
  },
  {
    title: '证书项目-师资团队',
    route: '/edu_project_teacher_team',
    icon: <UserOutlined />,
    hidden: true,
  },

  {
    title: '实战训练',
    route: '/entrepreneurship_practice',
    icon: <UserOutlined />,
    hidden: true,
    para: 'type',
  },
  {
    title: '创业孵化',
    route: '/entrepreneurship_incubation',
    icon: <UserOutlined />,
    hidden: true,
    children: [
      {
        title: '创业孵化-金种子孵化',
        route: '/entrepreneurship_incubation/gold_seed',
        icon: <UserOutlined />,
        hidden: true,
      },
      {
        title: '创业孵化-导师工坊',
        route: '/entrepreneurship_incubation/teacher_studio',
        icon: <UserOutlined />,
        hidden: true,
      },
      {
        title: '创业孵化-学生工坊',
        route: '/entrepreneurship_incubation/student_studio',
        icon: <UserOutlined />,
        hidden: true,
      },
      {
        title: '创业孵化-创客空间',
        route: '/entrepreneurship_incubation/space',
        icon: <UserOutlined />,
        hidden: true,
      },
    ]
  },
  {
    title: '新闻动态',
    route: '/news_list',
    icon: <UserOutlined />,
    hidden: true,
    para: 'key'
  },
  // {
  //   title: 'EDP',
  //   route: '/edp',
  //   icon: <UserOutlined />,
  //   hidden: true,
  // },


  {
    title: '基础信息管理',
    route: '/admin/basic',
    component: 'Course',
    children: [
      {
        title: '用户管理',
        route: '/admin/user',
        component: 'AdminUser',
      },
      {
        title: '轮播图管理',
        route: '/admin/banner',
        component: 'AdminUser',
      },
    ]
  },
  {
    title: '首页数据设定',
    route: '/admin/index_data',
    component: 'AdminUser',
  },
  {
    title: '关于我们',
    route: '/admin/teacher_team',
    component: 'TeacherTeam',
  },
  {
    title: '双创活动',
    route: '/admin/activity',
    component: 'Activity',
  },
  {
    title: '双创课程',
    route: '/admin/course',
    component: 'Course',
    children: [
      {
        title: '课程管理',
        route: '/admin/course/course',
        icon: <UserOutlined />,
      },
      {
        title: '学生路径',
        route: '/admin/course/student_path',
        icon: <UserOutlined />,
      },
    ]
  },
  {
    title: '证书项目',
    route: '/admin/education_project',
    component: 'Course',
    children: [
      {
        title: '致新班报名表',
        route: '/admin/education_project/form',
        icon: <UserOutlined />,
      },
    ]
  },
  {
    title: '新闻动态',
    route: '/admin/recent_news',
    component: 'RecentNews',
  },
  {
    title: '实战训练',
    route: '/admin/entrepreneurship_practice_course_show',
    component: 'RecentNews',
    children: [
      {
        title: '课程信息：X-lab创业实战课',
        route: '/admin/entrepreneurship_practice_course/x_lab',
        icon: <UserOutlined />,
      },
      {
        title: '课程信息：从行业案例到创业实战',
        route: '/admin/entrepreneurship_practice_course/industry',
        icon: <UserOutlined />,
      },
      {
        title: '课程展示：X-lab创业实战课',
        route: '/admin/entrepreneurship_practice_course_show/x_lab',
        icon: <UserOutlined />,
      },
      {
        title: '课程展示：从行业案例到创业实战',
        route: '/admin/entrepreneurship_practice_course_show/industry',
        icon: <UserOutlined />,
      },
    ]
  },
  {
    title: '创业孵化',
    route: '/admin/entrepreneurship',
    component: 'RecentNews',
    children: [
      {
        title: '金种子孵化报名表',
        route: '/admin/entrepreneurship_gold_seed_form',
        component: 'RecentNews',
      },
      {
        title: '导师工坊报名表',
        route: '/admin/entrepreneurship_teacher_studio_form',
        component: 'RecentNews',
      },
      {
        title: '学生工坊报名表',
        route: '/admin/entrepreneurship_student_studio_form',
        component: 'RecentNews',
      },
      {
        title: '创客空间报名表',
        route: '/admin/entrepreneurship_space_form',
        component: 'RecentNews',
      },
    ]
  },
]


